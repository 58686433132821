2<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
           <a href="javascript:;" @click="toList">班级课程参数</a>
          <i>></i>
          <a href="javascript:;"  class="cur-a">班级详情</a>
        </span>
      </div>
      <div class="framePage-body">
         <el-form :inline="true" ref="ruleForm" label-width="200px">
                <el-form-item label="班级名称:" class="form-inline" >
                  <p>{{detail.projectName}}</p>
                </el-form-item>
                 <el-form-item label="期次:" class="form-inline"> 
                  <p>{{detail.projectPc||"无"}}</p>
                </el-form-item>
                <el-form-item label="行政区划:" class="form-inline">
                  <p>{{ detail.areaId||"无"}}</p>
                </el-form-item>
                 <el-form-item label="培训类型:" class="form-inline">
                  <p>{{detail.trainTypeId||"无"}}</p>
                </el-form-item>
                 <el-form-item label="有效日期:" class="form-inline">
                  <p v-if="detail.startDate&&detail.endDate">{{detail.startDate|momentDate}}至{{detail.endDate|momentDate}}</p>
                   <p v-else>无</p>
                </el-form-item>
                 <el-form-item label="培训地点:" class="form-inline">
                  <p>{{detail.projectAddress||"无"}}</p>
                </el-form-item>
                 <el-form-item label="培训人数:" class="form-inline">
                  <p>{{detail.projectPeople||"无"}}</p>
                </el-form-item>
                 <el-form-item label="考试时间:" class="form-inline">
                  <p  v-if="detail.projectPaperDate">{{detail.projectPaperDate|momentDate}}</p>
                  <p v-else>无</p>
                </el-form-item>
                 <el-form-item label="报名截止时间:" class="form-inline">
                  <p  v-if="detail.signEndDate">{{detail.signEndDate|momentDate}}</p>
                  <p v-else>无</p>
                </el-form-item>
                 <el-form-item label="负责电话:" class="form-inline">
                  <p>{{detail.projectUserPhone||""}}</p>
                </el-form-item>
                 <el-form-item label="负责人:" class="form-inline">
                  <p>{{detail.projectUser||""}}</p>
                </el-form-item>
                 
                 <el-form-item label="申请表图片:" class="form-inline">
                  <el-image v-if="detail.applicationForm "
                      :src="detail.applicationForm || require('@/assets/develop.png')"
                      fit="contain"
                      class="imgCenter"
                    ></el-image>
                    <p v-else>无</p>
                </el-form-item>
              </el-form>
      </div>
    </div>

  </div>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  name: "SupervisionEnd/classSubjectParamDetail",
  components: {
   
  },

  data() {
    return {
    detail:{}
    };
  },
  created() {

  },
  computed: {
    ...mapGetters({
      downloadItems: 'getDownloadItems'
    })
  },
  mounted (){
    this.$post('/biz/project/query', {projectId:this.$route.query.id}).then(re => {
         this.detail=re.data;
      })
  },
  methods: {
    
    toList(){
      this.$router.push({
      path:'/web/classSubjectParam',
     
    })
    }
  }
};
</script>
<style lang="less">
.form-inline{
  width: 400px;
}
</style>
